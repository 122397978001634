import React from 'react'
import {
  chakra,
  FormControl as _FormControl,
  ImageProps,
  VStack,
  Image,
  TextProps,
  Text,
  Flex,
  FlexProps,
  Box,
  BoxProps,
  HStack,
  StackProps,
} from '@chakra-ui/react'
import { motion, MotionProps } from 'framer-motion'

export const Span = chakra('span', {
  baseStyle: {},
})

export const Video = chakra('video', {
  baseStyle: {},
})

export const FormControl = chakra(_FormControl, {
  baseStyle: {
    display: { md: 'grid' },
    gridTemplateColumns: { md: '1fr 2fr' },
    justifyContent: { md: 'center' },
    alignItems: { md: 'center' },
  },
})

export const MotionVStack = motion<Omit<StackProps, keyof MotionProps>>(VStack)

export const MotionHStack = motion<Omit<StackProps, keyof MotionProps>>(HStack)

export const MotionBox = motion<Omit<BoxProps, keyof MotionProps>>(Box)

export const MotionFlex = motion<Omit<FlexProps, keyof MotionProps>>(Flex)

export const MotionImage = motion<Omit<ImageProps, keyof MotionProps>>(Image)

export const MotionText = motion<Omit<TextProps, keyof MotionProps>>(Text)

// ref: https://chakra-ui.com/docs/form/checkbox#icon
export const CheckBoxIcon = (props: any) => {
  const { isIndeterminate, isChecked, ...rest } = props
  return isChecked || isIndeterminate ? (
    <Image {...rest} src="/landing/Icon/check.svg" />
  ) : null
}
