import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Link,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import NextLink from 'next/link'
import { useRouter } from 'src/api/router'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from 'src/contexts/Auth'
import { SignupFormInputs, signupSchema } from 'src/constants/form'
import { DisplayPlan } from '@gijirokukun/shared'
import { signIn } from 'src/models/user'
import { routes, useURL } from 'src/constants/routes'
import { CheckBoxIcon } from 'src/components/Landing/components'
import { alertSignUpError } from 'src/components/Organisms/Alert/authAlert'

export const SignupForm: React.FC<{
  callbackURL?: string
  selectedPlan?: DisplayPlan | undefined
}> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(signupSchema),
  })
  const { resetUser } = useAuth()
  const router = useRouter()
  const { toURL } = useURL()
  const [inSignupWithEmail, setInSignupWithEmail] = useState(false)

  const signUpWithEmail = (values: SignupFormInputs) => {
    if (!toURL) {
      console.error('Unexpected')
      return
    }
    setInSignupWithEmail(true)
    void router.prefetch(routes.login)
    signIn(
      'signUp',
      {
        type: 'email',
        isSignUp: true,
        name: values.username,
        email: values.email,
        password: values.password,
      },
      true,
      resetUser,
      router
    )
      .then(() => {
        void router.push({
          pathname: routes.login,
          query: {
            showSentVerifyEmailMessage: true,
            sentVerifyEmailAddress: values.email,
            callback: props.callbackURL ?? toURL(routes.comments),
          },
        })
      })
      .catch((error) => {
        setInSignupWithEmail(false)
        alertSignUpError(error)
      })
  }

  return (
    <form>
      <VStack spacing={{ base: '1rem', md: '1.5rem' }}>
        <FormControl isRequired isInvalid={errors.username?.message != null}>
          <FormLabel fontSize="0.75rem" mb="0.625rem">
            ユーザー名
          </FormLabel>
          <Input
            data-test="signUpForm-username"
            variant="filled"
            autoComplete="name"
            {...register('username')}
            placeholder="議事録くん"
          />
          <FormHelperText
            color="gray"
            fontSize="0.75rem"
            lineHeight="none"
            fontWeight="normal"
          >
            議事録での表示名に使われます
          </FormHelperText>
          <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={errors.email?.message != null}>
          <FormLabel fontSize="0.75rem" mb="0.625rem">
            メールアドレス
          </FormLabel>
          <Input
            data-test="signUpForm-email"
            variant="filled"
            type="email"
            autoComplete="username"
            {...register('email')}
            placeholder="example@gijirokukun.com"
          />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={errors.password?.message != null}>
          <FormLabel fontSize="0.75rem" mb="0.625rem">
            パスワード
          </FormLabel>
          <Input
            data-test="signUpForm-password"
            variant="filled"
            type="password"
            autoComplete="new-password"
            {...register('password')}
            placeholder="半角英数8文字以上"
          />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.passwordConfirm?.message != null}>
          <FormLabel fontSize="0.75rem" mb="0.625rem">
            パスワード（確認）
          </FormLabel>
          <Input
            data-test="signUpForm-passwordConfirm"
            variant="filled"
            type="password"
            autoComplete="new-password"
            {...register('passwordConfirm')}
            placeholder=""
          />
          <FormErrorMessage>{errors.passwordConfirm?.message}</FormErrorMessage>
        </FormControl>
        <Spacer flexBasis="0.625rem" mt="0 !important" />
        <FormControl
          isRequired
          isInvalid={errors.isAgreed?.message != null}
          textAlign="center"
        >
          <Checkbox
            data-test="signUpForm-input-isAgreed"
            size="sm"
            icon={<CheckBoxIcon />}
            colorScheme="white"
            {...register('isAgreed')}
            mb="0.625rem"
            w="102%"
          >
            <NextLink href={routes.terms} passHref>
              <Link color="primary" isExternal>
                利用規約
              </Link>
            </NextLink>
            {'と'}
            <NextLink href={routes.privacy} passHref>
              <Link color="primary" isExternal>
                プライバシーポリシー
              </Link>
            </NextLink>
            {'に同意する'}
          </Checkbox>
          <FormErrorMessage>{errors.isAgreed?.message}</FormErrorMessage>
        </FormControl>
        <Button
          data-test="signUpForm-button-submit"
          colorScheme="primary"
          variant="solid"
          w="100%"
          h="4rem"
          borderRadius="2rem"
          onClick={handleSubmit(signUpWithEmail)}
          isLoading={inSignupWithEmail}
          fontWeight="bold"
          fontSize="0.875rem"
          lineHeight="none"
          color="white"
        >
          {props.selectedPlan?.canTrialOnWeb ?? false
            ? '7日間無料体験'
            : '登録'}
        </Button>
      </VStack>
    </form>
  )
}

export const SignupButton: React.FC<BoxProps & ButtonProps> = (props) => {
  return (
    <Box
      as="button"
      pos="fixed"
      w="full"
      left={0}
      right={0}
      marginX="auto !important"
      top={{ base: '76vh', md: '88vh' }}
      h="4rem"
      borderRadius="full"
      p="1rem"
      bg="primary"
      borderWidth="0.1875rem"
      borderColor="white"
      boxShadow="0rem 0.375rem 0.25rem rgba(0, 0, 0, 0.1)"
      textAlign="center"
      zIndex="10"
      maxW="18rem"
      onClick={props.onClick}
      cursor="pointer"
    >
      <HStack spacing="0.5rem" cursor="pointer">
        <Text
          data-test="signUp-button-openSignUpForm"
          as="span"
          lineHeight="none"
          color="white"
          fontWeight="bold"
          cursor="pointer"
        >
          まずは無料で試す
        </Text>
        <Text
          data-test="signUp-button-openSignUpForm"
          as="span"
          lineHeight="none"
          color="white"
          fontSize="xs"
          cursor="pointer"
        >
          または
        </Text>
        <Text
          data-test="signUp-button-openSignUpForm"
          as="span"
          lineHeight="none"
          color="white"
          fontWeight="bold"
          cursor="pointer"
        >
          ログイン
        </Text>
      </HStack>
    </Box>
  )
}
