import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  styles: {
    global: {
      'img, video': {
        maxW: 'none',
      },
      '*': {
        fontFamily: 'Noto Sans JP',
      },
    },
  },
  colors: {
    white: '#FFFFFF',
    lightGray: '#EBF0F7',
    lightGray2: '#E8E8E9',
    gray: '#919CAB',
    'gray.50': '#ECEFF5',
    'gray.100': '#ECEFF5',
    'gray.200': '#ECEFF5',
    'gray.300': '#ECEFF5',
    'gray.400': '#ECEFF5',
    'gray.500': '#ECEFF5',
    'gray.600': '#ECEFF5',
    'gray.700': '#0D0D0D',
    'gray.800': '#0D0D0D',
    'gray.900': '#0D0D0D',
    black: '#0D0D0D',
    'black.400': '#0D0D0D',
    'black.500': '#0D0D0D',
    'black.600': '#0D0D0D',
    primary: '#264297',
    'primary.400': '#264297',
    'primary.500': '#264297',
    'primary.600': '#264297',
    secondary: '#ECEFF5',
    secondary2: '#EEEEEE',
    error: '#E94141',
    medal: '#CDB94D',
  },
  components: {
    Text: {
      baseStyle: {
        color: 'black',
        fontWeight: 'normal',
        cursor: 'default',
      },
      variants: {
        mainHeadingDescription: {
          fontWeight: 400,
          fontSize: ['0.875rem', '0.875rem', '1rem'],
          color: 'primary',
          lineHeight: 'none',
        },
      },
    },
    Heading: {
      baseStyle: {
        cursor: 'default',
      },
      variants: {
        main: {
          fontWeight: 700,
          fontSize: ['2rem', '2.125rem', '2.25rem', '2.375rem', '2.5rem'],
          fontFamily: 'Rubik',
          lineHeight: 'none',
          color: 'primary',
          // メニューの高さ分ずらすことでURLでジャンプしたときにいい感じになるハック
          // ref: https://www.tam-tam.co.jp/tipsnote/html_css/post4776.html
          // 見やすいように少し多めにずらしたりした
          mt: '-7rem',
          pt: '7rem',
        },
      },
    },
    Switch: {
      baseStyle: {
        track: {
          bg: 'primary',
          alignItems: 'center',
          p: '0',
        },
        thumb: {
          boxSize: '1.5rem',
          ml: '0.25rem',
        },
      },
      sizes: {
        lg: {
          track: {
            w: '3.5rem',
            h: '2rem',
          },
          thumb: {
            boxSize: '1.5rem',
          },
        },
      },
      defaultProps: {
        colorScheme: 'primary',
      },
    },
    Tabs: {
      variants: {
        'soft-rounded': {
          tab: {
            bg: 'white',
            color: 'gray',
            _selected: {
              bg: 'primary',
              color: 'white',
            },
          },
        },
      },
    },
    Form: {
      baseStyle: {
        requiredIndicator: {
          color: 'error',
          fontWeight: 'bold',
          fontSize: '0.75rem',
          flexShrink: '0',
          visibility: 'hidden',
          _after: {
            visibility: 'visible',
            content: '"※必須"',
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: 'bold',
        fontSize: { base: '0.875rem', md: '1rem' },
        lineHeight: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        mr: '0',
      },
      variants: {
        grid: {
          mb: { base: '1rem', md: '0rem' },
          p: { base: '0rem', md: '1.5rem' },
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          _placeholder: {
            color: 'primary',
            opacity: 0.5,
            fontSize: '0.875rem',
          },
        },
      },
      sizes: {
        md: {
          field: {
            h: '3rem',
            borderRadius: '0.75rem',
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderRadius: '0.25rem',
          borderColor: 'secondary2',
          _checked: {
            bg: 'white !important',
          },
        },
      },
      sizes: {
        sm: {
          control: {
            w: '1.25rem',
            h: '1.25rem',
          },
          label: {
            fontSize: '0.75rem',
          },
        },
        md: {
          control: {
            w: '1.25rem',
            h: '1.25rem',
          },
        },
      },
    },
    List: {
      variants: {
        article: {
          container: {
            paddingInlineStart: '1.5rem',
            spacing: '0.5rem',
          },
        },
      },
    },
  },
})
