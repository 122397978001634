import * as yup from 'yup'

/** 任意長の半角英数記号を取る正規表現 */
const VALID_PASSWORD_WORDS_REGEX = /^[\x20-\x7E]+$/
/** 大文字小文字数字記号をそれぞれ１つ以上含む正規表現
 * \x20-\x2Fは" "から"/"
 * \x3A-\x40は":"から"@"
 * \x5B-\x60は"["から"`"
 * \x7B-\x7Eは"{"から"~"
 */
export const COMPLEX_PASSWORD_REGEX =
  /(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])/

export const signupSchema = yup.object({
  username: yup.string().required('ユーザー名を入力してください'),
  email: yup
    .string()
    .required('メールアドレスを入力してください')
    .email('無効なメールアドレスです'),
  password: yup
    .string()
    .required('パスワードを入力してください')
    .matches(
      VALID_PASSWORD_WORDS_REGEX,
      'パスワードにはアルファベット・数字・記号のみ使用できます'
    )
    .min(8, 'パスワードは8文字以上入力してください'),
  passwordConfirm: yup
    .mixed()
    .test('match', 'パスワードが一致しません', function () {
      return this.parent.password === this.parent.passwordConfirm
    }),
  isAgreed: yup
    .boolean()
    .required('必ず同意してください')
    .isTrue('必ず同意してください'),
})

export type SignupFormInputs = yup.InferType<typeof signupSchema>

export const loginSchema = yup.object({
  email: yup
    .string()
    .required('メールアドレスを入力してください')
    .email('無効なメールアドレスです'),
  password: yup.string().required('パスワードを入力してください'),
})

export type LoginFormInputs = yup.InferType<typeof loginSchema>
